import * as dompack from 'dompack';
import * as whintegration from '@mod-system/js/wh/integration';
import '@mod-system/js/wh/integration';
import $ from 'jquery';
//import * as page from '../../shared/js/page';
import * as wrdauth from '@mod-wrd/js/auth';

import "./event.scss";

const isValidEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

dompack.onDomReady(() => {

  //console.log("event js here");

  //removeCookie("event");
  if ($("html").hasClass("page--event")) {

    // fixme: jannes: make validation more general for forms
    $(document).on("change", ".formfield.required select", function(e) {
      const novaluetext = "Er is niets ingevuld";
      const $formfield = $(this).closest(".formfield");
      var $small = $formfield.find("small");
      const $select = $(this);
      if ($select.val().length) {
        if ($small.length) {
          $small.addClass("hidden");
        }
      }
      else {
        if ($small.length) {
          $small.text(novaluetext);
          $small.removeClass("hidden");
        }
        else {
          $inputgroup.after('<small class="error">' + novaluetext + '</small>');
        }
      }
    });
 
    $(document).on("change", ".formfield.required input[type=checkbox]", function(e) {
      const novaluetext = "Er is niets ingevuld";
      const $formfield = $(this).closest(".formfield");
      var $inputgroup = $formfield.find(".inputgroup");
      var $small = $formfield.find("small");
      if ($(this).prop("checked")) {
        if ($small.length) {
          $small.addClass("hidden");
        }
      }
      else {
        if ($small.length) {
          $small.text(novaluetext);
          $small.removeClass("hidden");
        }
        else {
          $inputgroup.after('<small class="error">' + novaluetext + '</small>');
        }
      }
    });

    $(document).on("input", ".formfield.required input[type=text]", function(e) {
      const novaluetext = "Er is niets ingevuld";
      const novalidemailtext = "Dit is (nog) geen geldig e-mailadres";
      const $input = $(this);
      const $formfield = $input.closest(".formfield");
      const hasclassemail = $formfield.hasClass("email");
      var $small = $formfield.find(".part2 small");
      if ($input.length && $input.val().length==0) {
        $input.addClass("error");
        if ($small.length) {
          $small.text(novaluetext);
          $small.removeClass("hidden");
        }
        else {
          $input.after('<small class="error">' + novaluetext + '</small>');
        }
      }
      else {
        $input.removeClass("error");
        if ($small.length) {
          $small.addClass("hidden");
        }
        if (hasclassemail) {
          if (! isValidEmail($input.val())) {
            if ($small.length) {
              $small.text(novalidemailtext);
              $small.removeClass("hidden");
            }
            else {
              $input.after('<small class="error">' + novalidemailtext + '</small>');
            }
          }
        }
      }
    });

/*
    let cookie = getCookie("event");
    console.log("getCookie: ", cookie);
    if (cookie === undefined) {
      cookie = {
        count: 1
      }
    }
    else {
      cookie = JSON.parse(cookie);
      cookie.count += 1;
    }
    console.log("getCookie: ", cookie);
    setCookie("event", JSON.stringify(cookie));

    let mydata = localStorage.getItem("eventdata");
    console.log("mydata:", mydata);
    if ( (mydata === undefined) || (mydata === null) ) {
      mydata = {
        count: 1
      , store: "local"
      }
    }
    else {
      mydata = JSON.parse(mydata);
      mydata.count += 1;
    }
    console.log("mydata:", mydata);
    localStorage.setItem("eventdata", JSON.stringify(mydata));

    if (wrdauth.getDefaultAuth().isLoggedIn())
    {
    }
*/
  }
});

// Set a cookie
function setCookie(_name, _value, _expiredays) {
  let date = new Date();
  date.setTime(date.getTime() + (_expiredays * 24 * 60 * 60 * 1000));
  const expires = "expires=" + date.toUTCString();
  document.cookie = _name + "=" + _value + "; " + expires + "; path=/";
}

function removeCookie(_name) {
  const expires = "expires=Thu, 01 Jan 1970 00:00:00 UTC";
  document.cookie = _name + "=" + "remove" + "; " + expires + "; path=/";
}

// Get a cookie
function getCookie(_name) {
  const name = _name + "=";
  const decoded_cookie = decodeURIComponent(document.cookie); //to be careful
  const cookie_array = decoded_cookie .split('; ');
  let result;
  cookie_array.forEach(val => {
    if (val.indexOf(_name) === 0) {
      result = val.substring(_name.length+1);
    }
  })
  return result;
}

export default class EventForm {
  // node is the container with class "menubar"
  constructor(_node) {
    //console.log("setup event form", _node);
    var classthis = this;
    var $form = $(_node);
    this.$totalamount = $form.find("h2.totalamount");
    this.totalamount = this.$totalamount.data("amountrequired");
    //console.log($form);
    $(document).on("change", ".formfield.optional input[type=checkbox]", function(e) {
      //console.log("on change");
      classthis.refreshTotalAmount();
    });
    this.refreshTotalAmount();
  }

  refreshTotalAmount() {
    let newtotalamount = Number(this.totalamount);
    //console.log("newtotalamount", newtotalamount);
    $(".formfield.optional input[type=checkbox]").each( function(_index, _element) {
      let $element = $(_element);
      let pamount = $element.data("amount");
      //console.log("amount", pamount);
      if ($element.prop("checked")) {
        newtotalamount += Number(pamount);
        //console.log("newtotalamount", newtotalamount);
      }    
    });
    this.$totalamount.find("span.totalamount").text(_getMoney(newtotalamount, 2));
  }

}

dompack.register('#eventform', node => new EventForm(node));

function _getMoney(value, decimals) {
  return Number(Math.round(value+'e'+decimals)+'e-'+decimals).toLocaleString("nl-NL", {minimumFractionDigits: 2, maximumFractionDigits: 2}); //.toFixed(decimals);
}

