import * as whintegration from "@mod-system/js/wh/integration";
import * as dompack from 'dompack';
//import $ from 'jquery';
//import { $ as $j } from 'jquery';
//import { $ as $j, jQuery as jq } from 'jquery';
//import { $, jQuery } from 'jquery';

import "./clubsearch.scss";

//var $ = window.jQuery.noConflict();
//window.jQuery = $;
//var $;

if (! window.jQuery) {
}
else {
}

var validkuiautocomplete = false;
var curlatitude=0;
var curlongitude=0;
var curfilter="";
var clubmarkericonurl = whintegration.config.imgroot + "pr-logo-34x34.png";

/*
var $jq = window.jQuery.noConflict();
var $ = $j;
*/

/*
function addHeadScript( _src ) {
  var s = document.createElement( 'script' );
  s.setAttribute( 'src', _src );
  document.head.appendChild( s );
}

addHeadScript(whintegration.config.designroot + "js/jquery-ui-map.js");
addHeadScript(whintegration.config.designroot + "js/kendo.custom.06012016.min.js");
console.log(whintegration.config);
*/

dompack.onDomReady(() => {

if (document.documentElement.classList.contains("page--hasgmap"))
{
/*
if (window.jQuery) {
*/
  function __scrollToMap() {
    var target = $("#map_canvas");
    $('html,body').animate({
      scrollTop: target.offset().top
    }, 1000);
  }

/*
    $(function() {
*/
      if ($('#map_canvas').length > 0) {
//console.log("#map_canvas");
        $('#map_canvas').gmap('option', 'zoom', 7);
        var centernl = new google.maps.LatLng(52.15611, 5.38782);
        $('#map_canvas').gmap('get', 'map').panTo(centernl);
      }
/*
    });
*/

    $(".formfield.kendo.club").hide();

    var autocomplete = $("#filtercityname").kendoAutoComplete({
      minLength: 1,
      dataTextField: "displayname",
      dataSource: {
        serverFiltering: true,
        transport: {
          read: {
            dataType: "json",
            //url: "//www.past-rotarians.rotarynew.wevolve.nl/data.shtml?action=getAutocompleteCities"
            url: "/data.shtml?action=getAutocompleteCities"
          }
        }
      },
      open: function(e){
        validkuiautocomplete = false;
      },
      select: function(e) {
        if (this.dataItem) {
          //alert("select: " + this.dataItem(e.item.index()) );
          //console.log(this.dataItem(e.item.index()));
          //console.log(this.dataItem(e.item.index()).latitude);
          //console.log(this.dataItem(e.item.index()).longitude);
          var radius = $("#radius").val();
          curlatitude = this.dataItem(e.item.index()).latitude;
          curlongitude = this.dataItem(e.item.index()).longitude;
          curfilter = this.dataItem(e.item.index()).displayname;
          validkuiautocomplete = true;
          setClubMarkers({ latitude: this.dataItem(e.item.index()).latitude, longitude: this.dataItem(e.item.index()).longitude, radius: radius, markcity: true, filter: curfilter}, clubmarkericonurl);
          __scrollToMap();
        }
      },
      close: function(e){
        // if no valid selection - clear input
        if (!validkuiautocomplete) {
          this.value('');
          curlatitude = 0;
          curlongitude = 0;
          curfilter = "";
        }
      },
      height: 250
    }).data("kendoAutoComplete");

    var autocomplete = $("#filterclubname").kendoAutoComplete({
      minLength: 1,
      dataTextField: "displayname",
      dataSource: {
        serverFiltering: true,
        transport: {
          read: {
            dataType: "json",
            //url: "//www.past-rotarians.rotarynew.wevolve.nl/data.shtml?action=getAutocompleteCities"
            url: "/data.shtml?action=getAutocompleteClubs"
          }
        }
      },
      open: function(e){
        validkuiautocomplete = false;
      },
      select: function(e) {
        if (this.dataItem) {
          //alert("select: " + this.dataItem(e.item.index()) );
          //console.log(this.dataItem(e.item.index()).latitude);
          //console.log(this.dataItem(e.item.index()).longitude);
          //var radius = $("#radius").val();
          var radius = 1;
          curlatitude = this.dataItem(e.item.index()).latitude;
          curlongitude = this.dataItem(e.item.index()).longitude;
          curfilter = this.dataItem(e.item.index()).displayname;
          validkuiautocomplete = true;
          setClubMarkers({ latitude: this.dataItem(e.item.index()).latitude, longitude: this.dataItem(e.item.index()).longitude, radius: radius, filter: curfilter}, clubmarkericonurl);
          __scrollToMap();
        }
      },
      close: function(e){
        // if no valid selection - clear input
        if (!validkuiautocomplete) {
          this.value('');
          curlatitude = 0;
          curlongitude = 0;
          curfilter = "";
        }
      },
      height: 250
    }).data("kendoAutoComplete");

// ---------- Zoeken in Clubs/Plaats op Google Maps
    $(function() {
      $(document).on("submit", "#formsearchclub2", function(e) {
        e.preventDefault();
        setClubMarkersC();
      });
      $(document).on("change", "#search4", function(e) {
        //alert("change search4");
        $("#filtercityname").val("");
        $("#filterclubname").val("");
        curlatitude = 0;
        curlongitude = 0;
        curfilter = "";
        //$("#map_canvas").gmap('clear','markers')
        //if ($('#map_canvas').length > 0) {
        //   $('#map_canvas').gmap('option', 'zoom', 7);
        //   var centernl = new google.maps.LatLng(52.15611, 5.38782);
        //     $('#map_canvas').gmap('get', 'map').panTo(centernl);
        //}
        var choice = $("#search4").val();
        if (choice === "clubnaam") {
          $(".formfield.radius").hide();
          $(".formfield.kendo.club").show();
          $(".formfield.kendo.city").hide();
        }
        else {
          $(".formfield.radius").show();
          $(".formfield.kendo.club").hide();
          $(".formfield.kendo.city").show();
        }
        setClubMarkersC();
      });
      $(document).on("change", "#radius", function(e) {
        //alert("change radius");
        setClubMarkersC();
      });
      $(document).on("change", "#filtercityname", function(e) {
        //alert("change filtercityname");
        //setClubMarkersC();
      });
      $(document).on("change", "#filterclubname", function(e) {
        //alert("change filterclubname");
        //setClubMarkersC();
      });

      function setClubMarkersC() {
        var choice = $("#search4").val();
        var radius = $("#radius").val();
        if ( (curlatitude>0) && (curlongitude>0) ) {
          //alert("has lat/long");
          if (choice === "clubnaam") {
            setClubMarkers({ latitude: curlatitude, longitude: curlongitude, filter: curfilter}, clubmarkericonurl);
          }
          else {
            setClubMarkers({ latitude: curlatitude, longitude: curlongitude, radius: radius, markcity: true, filter: curfilter}, clubmarkericonurl);
          }
          scrollToMap();
        }
        else {
          $("#map_canvas").gmap('clear','markers')
          if ($('#map_canvas').length > 0) {
             $('#map_canvas').gmap('option', 'zoom', 7);
             var centernl = new google.maps.LatLng(52.15611, 5.38782);
             $('#map_canvas').gmap('get', 'map').panTo(centernl);
          }
        }
        //alert("set clubmarkers");
      }

      function scrollToMap() {
        var target = $("#map_canvas");
        $('html,body').animate({
          scrollTop: target.offset().top
        }, 1000);
      }

    });

}
/*
}
*/

});

