import * as whintegration from "@mod-system/js/wh/integration";
import $ from 'jquery';
import * as page from './page';

export function initFormFields()
{
  initAddressFields();
  initImageFields();
}

export function initAddressFields()
{
  //console.log("init address fields");
  $(document).on("change", ".formfield.country select", function(e) {
    console.log("country change");
    var countryformfield = $(this).closest(".formfield.country");
    var thisform = $(this).closest("form");
    var thisformid = thisform.attr("id");
    console.log($(this).val());
    var ajaxData = { countrycode: $(this).val() };
    page.showLoader();
    $.ajax({
      url: "/data.shtml?action=getEditAddressFormData&countrycode=" + $(this).val() + "&formname=" + thisformid,
      type: "POST",
      data: ajaxData,
      dataType: 'json',
      cache: false,
      contentType: false,
      processData: false,
      complete: function() {
        //thisform.removeClass('is-uploading');
      },
      success: function(_data) {
        console.log(_data);
        setTimeout(function() {
          page.hideLoader();
        }, 200);
        console.log(_data);
        thisform.find(".addresselement").remove();
        countryformfield.after(_data.html);
      }
    });
  });

  $(document).on("change", ".formfield.addresselement.checkzipnrnl input", function(e) {
    //console.log("check address change");
    var thisform = $(this).closest("form");
    var zip = thisform.find("div.edit .formfield.zip input").val();
    var nr_detail = thisform.find("div.edit .formfield.nr_detail input").val();

    if ( (zip!="") && (nr_detail!="") )
    {
      var formData = new FormData();
      formData.append('country', thisform.find("div.edit .formfield.country select").val());
      formData.append('zip', zip);
      formData.append('nr_detail', nr_detail);
      formData.append('formname', thisform.attr("id"));
      page.showLoader();
      $.ajax({
        url: "/data.shtml?action=checkNLAddress",
        type: "POST",
        data: formData,
        dataType: 'json',
        cache: false,
        contentType: false,
        processData: false,
        complete: function() {
          //thisform.removeClass('is-uploading');
        },
        success: function(_data) {
          console.log(_data);
          setTimeout(function() {
            page.hideLoader();
          }, 500);
          if (_data.ok)
          {
            var thisformfeedback = thisform.find("div.formfeedback.error");
            thisformfeedback.addClass("hidden");
            thisform.find(".formfield input.error").removeClass("error");
            $(thisform).find("small.error").addClass("hidden");
            if ( (_data.item) && (_data.item.lookupok) )
            {
              thisform.find("div.edit .formfield.addresselement.street input").val(_data.item.street);
              thisform.find("div.edit .formfield.addresselement.city input").val(_data.item.city);
            }
            else {
              if (_data.item.formfeedbackerror)
              {
                thisformfeedback.find("p strong").html(_data.item.formfeedbackerror);
                thisformfeedback.removeClass("hidden");
              }
              if (_data.item.posterrors)
              {
                for (var fieldname in _data.item.posterrors) {
                  var field = $("#" + fieldname + "Field");
                  var smallerror = field.next("small.error");
                  if (_data.item.posterrors[fieldname]!="") {
                    field.addClass("error");
                    if (smallerror.length>0) {
                      smallerror.text(_data.item.posterrors[fieldname]);
                      smallerror.removeClass("hidden");
                    }
                    else {
                      field.after('<small class="error">' + _data.item.posterrors[fieldname] + '</small>');
                    }
                  }
                  else {
                    field.removeClass("error");
                    if (smallerror.length>0) {
                      smallerror.addClass("hidden");
                    }
                  }
                } 
                thisformfeedback.removeClass("hidden");
                $('html, body').animate({
                  //scrollTop: thisformfeedback.offset().top
                  scrollTop: thisform.offset().top
                }, 1000);
              }
            }
          }
        }
      });
    }
  });

}

export function initImageFields()
{
  //console.log("init image fields");
  $("form.standard .formfield.image").each( function(_index, _item) {
    var formfield = $(_item);
    var htmlform = formfield.closest("form.standard");
    var datasettings = formfield.data("settings");
    //console.log("datasettings");
    //console.log(datasettings);
    if (datasettings !== undefined) {
      formfield.find(".part2 .imgcontainer").css({'width':datasettings.previewwidth, 'height':datasettings.previewheight});
    }
    $(document).on("change", "form.standard .formfield.image .filedeletelabel input[type='checkbox']", function(e) {
      console.log("delete image");
      console.log(this);
      //console.log(annoncedata);
      if (this.checked) {
        formfield.find(".part2 .image .imgcontainer img").attr("src", whintegration.config.imgroot + "preview-no-image.png");
        formfield.find(".part2 .filedeletelabel").addClass("hidden");
        formfield.find(".part2 small.error").addClass("hidden");
      }
    });
    $(document).on("change", "input.file", function(e) {
      var input = this;
      //console.log(this);
      var formData = new FormData();
      var url = $(input).val();
      var ext = url.substring(url.lastIndexOf('.') + 1).toLowerCase();
      if (input.files && input.files[0] && (ext == "gif" || ext == "png" || ext == "jpeg" || ext == "jpg"))
      {
        formData.append('filename', input.files[0].name);
        formData.append('filedata', input.files[0], input.files[0].name);
        /*
        var reader = new FileReader();
        reader.onload = function (e) {
          formData.append('data', e.target.result);
          console.log("formdata.data appended");
        };
        reader.readAsDataURL(input.files[0]);
        */
      }
      if (datasettings) {
        formData.append('setwidth', datasettings.previewwidth);
        formData.append('setheight', datasettings.previewheight);
      }
      page.showLoader();
      $.ajax({
        url: "/data.shtml?action=getResizedImage",
        type: "POST",
        data: formData,
        dataType: 'json',
        cache: false,
        contentType: false,
        processData: false,
        complete: function() {
          //thisform.removeClass('is-uploading');
        },
        success: function(_data) {
          console.log(_data);
          setTimeout(function() {
            page.hideLoader();
          }, 500);
          if (_data.ok)
          {
            formfield.find(".part2 .image .imgcontainer img").attr("src", _data.item.filedataurl);
            formfield.find(".part2 .filedeletelabel").removeClass("hidden");
            formfield.find(".part2 small.error").addClass("hidden");
console.log("set checked false");
            formfield.find(".part2 .filedeletelabel input[type='checkbox']").prop("checked", false);
          }
          else {
            /*
            if (_data.item.posterrors)
            {
              for (fieldname in _data.item.posterrors) {
                var field = $("#" + fieldname);
                var smallerror = field.next("small.error");
                if (_data.item.posterrors[fieldname]!="") {
                  field.addClass("error");
                  if (smallerror.length>0) {
                    smallerror.text(_data.item.posterrors[fieldname]);
                    smallerror.removeClass("hidden");
                  }
                  else {
                    field.after('<small class="error">' + _data.item.posterrors[fieldname] + '</small>');
                  }
                }
                else {
                  field.removeClass("error");
                  if (smallerror.length>0) {
                    smallerror.addClass("hidden");
                  }
                }
              }
              $('html, body').animate({
                //scrollTop: thisformfeedback.offset().top
                //scrollTop: thisform.offset().top
              }, 1000);
            }
            */
          }
        }
      });
    });
  });
}
