import * as dompack from 'dompack';
import $ from 'jquery';

import "./person.scss";

dompack.onDomReady(() => {

  $(document).on("click", ".memberdata .person__photo-toggle", function(_e) {
    _e.preventDefault();
    let $element = $(this);
    $element.parent().toggleClass("flex-row flex-column");
  });

});

