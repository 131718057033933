import * as dompack from 'dompack';
import $ from 'jquery';
import * as page from '../../page'

import "./allclubslist.scss";

dompack.onDomReady(() => {

  if ($(".clubcontactform").length>0) {
// ---------- Clubcontactform
    $(document).on("click", ".clubcontactform.widget.content .title", function(e) {
      var clubcontactformwidget = $(this).closest(".clubcontactform.widget");
      var formid = clubcontactformwidget.data("formid");
      var form = clubcontactformwidget.find("form");
      if (form.length===0) {
        var formhtml = getClubContactFormHTML(formid);
        $(this).closest(".widget").find("> .content").html(formhtml);
        setTimeout(function() {
          $(this).closest(".widget").find("#codeField" + formid).val("250620200000");
        }, 500);
      }
      $(this).closest(".widget").find("> .content").slideToggle("slow").toggleClass("close");
      $(this).closest(".widget").find("span.open").toggleClass("nodisplay");
      $(this).closest(".widget").find("span.close").toggleClass("nodisplay");
    });

    $(document).on("click", ".clubcontactform.widget.content .loadform", function(e) {
      e.preventDefault();
      var clubcontactformwidget = $(this).closest(".clubcontactform.widget");
      var formid = clubcontactformwidget.data("formid");
      var formhtml = getClubContactFormHTML(formid);
      $(this).closest(".widget").find("> .content").html(formhtml);
      setTimeout(function() {
        $(this).closest(".widget").find("#codeField" + formid).val("250620200000");
      }, 500);
    });


    $(document).on("submit", ".clubcontactform.widget form.standard", function(e) {
      var thisform = $(this);
      var clubcontactformwidget = $(this).closest(".clubcontactform.widget");
      var formid = clubcontactformwidget.data("formid");
      var dataclub = clubcontactformwidget.data("club");
      e.preventDefault();
      //console.log("submit: " + formid);
      //console.log(thisform.serialize());
      var thisformid = thisform.attr("id");
      var ajaxData = new FormData(document.getElementById(thisformid));
      //console.log(ajaxData);
      page.showLoader();
      var fieldname;
      $.ajax({
        url: "/data.shtml?action=handleClubContactForm&formname=" + thisformid + "&dc=" + dataclub,
        type: "POST",
        data: ajaxData,
        dataType: 'json',
        cache: false,
        contentType: false,
        processData: false,
        complete: function() {
          //thisform.removeClass('is-uploading');
        },
        success: function(_data) {
          console.log(_data);
          setTimeout(function() {
            page.hideLoader();
          }, 500);
          if (_data.ok)
          {
            var thisformfeedback = thisform.find("div.formfeedback.error");
            thisformfeedback.addClass("hidden");
            thisform.find(".formfield input.error").removeClass("error");
            $(thisform).find("small.error").addClass("hidden");
            if (_data.item)
            {
              if (_data.item.anyerrors)
              {
                for (fieldname in _data.item.posterrors) {
console.log("fieldname: " + fieldname);
                  var field = $("#" + fieldname + "Field" + formid);
                  var smallerror = field.next("small.error");
                  if (_data.item.posterrors[fieldname]!="") {
                    field.addClass("error");
                    if (smallerror.length>0) {
                      smallerror.text(_data.item.posterrors[fieldname]);
                      smallerror.removeClass("hidden");
                    }
                    else {
                      field.after('<small class="error">' + _data.item.posterrors[fieldname] + '</small>');
                    }
                  }
                  else {
                    field.removeClass("error");
                    if (smallerror.length>0) {
                      smallerror.addClass("hidden");
                    }
                  }
                }
                thisformfeedback.removeClass("hidden");
                $('html, body').animate({
                  //scrollTop: thisformfeedback.offset().top
                  //scrollTop: thisform.offset().top
                  scrollTop: clubcontactformwidget.offset().top
                }, 1000);
              }
              else // not anyerrors
              {
                if (_data.item.feedbackhtml) {
                  clubcontactformwidget.find("> .content").html(_data.item.feedbackhtml);
                  $('html, body').animate({
                    scrollTop: clubcontactformwidget.offset().top
                  }, 1000);
                }
              }
            } // _data.item
          } // _data.ok
        } // success
      }); // ajax
    });

  }

});


function getClubContactFormHTML(_formid)
{
  var html = "";

  html += '<form id="clubcontactform' + _formid + '" method="post" action="#contactform" class="standard labelsabove" enctype="multipart/form-data">\n';
  html += '  <fieldset>\n';
  html += '    <legend class="nodisplay"><span></span></legend>\n';
  html += '    <div class="content">\n';
  html += '    <div class="formfield required">\n';
  html += '      <div class="part1">\n';
  html += '        <label for="fromnameField' + _formid + '">Uw naam *: </label>\n';
  html += '      </div>\n';
  html += '      <div class="part2">\n';
  html += '        <input id="fromnameField' + _formid + '" name="fromname" class="text" type="text" value="" maxlength="100">\n';
  html += '      </div>\n';
  html += '    </div>\n';
  html += '    <div class="formfield required">\n';
  html += '      <div class="part1">\n';
  html += '        <label for="emailField' + _formid + '">Uw e-mailadres *: </label>\n';
  html += '      </div>\n';
  html += '      <div class="part2">\n';
  html += '        <input id="emailField' + _formid + '" name="email" class="text" type="text" value="" maxlength="200">\n';
  html += '      </div>\n';
  html += '    </div>\n';
  html += '    <div class="formfield required">\n';
  html += '      <div class="part1">\n';
  html += '        <label for="subjectField' + _formid + '">Onderwerp *: </label>\n';
  html += '      </div>\n';
  html += '      <div class="part2">\n';
  html += '        <input id="subjectField' + _formid + '" name="subject" class="text" type="text" value="" maxlength="100">\n';
  html += '      </div>\n';
  html += '    </div>\n';
  html += '    <div class="formfield required countchars">\n';
  html += '      <div class="part1">\n';
  html += '        <label for="messageField' + _formid + '">Uw bericht en/of vraag *: </label>\n';
  html += '      </div>\n';
  html += '      <div class="part2">\n';
  html += '        <textarea id="messageField' + _formid + '" name="message" class="text"></textarea>\n';
  html += '        <small class="counter"></small>\n';
  html += '      </div>\n';
  html += '    </div>\n';
  html += '    <div class="formfield nodisplay">\n';
  html += '      <div class="part1">\n';
  html += '        <label for="codeField' + _formid + '">Voer code in : </label>\n';
  html += '      </div>\n';
  html += '      <div class="part2">\n';
  //html += '        <input id="codeField' + _formid + '" name="code" class="text" type="text" value="0200741713" maxlength="100">\n';
  html += '        <input id="codeField' + _formid + '" name="code" class="text" type="text" value="250620200000" maxlength="100">\n';
  html += '      </div>\n';
  html += '    </div>\n';
  html += '    </div>\n';
  html += '  </fieldset>\n';
  html += '  <div class="formfooter">\n';
  html += '    <input type="submit" value="Opsturen" class="button small">\n';
  html += '  </div>\n';
  html += '</form>\n';
  return html;
}
