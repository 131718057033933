import * as dompack from 'dompack';
import $ from 'jquery';

import "./email.scss";

dompack.onDomReady(() => {

  //console.log("appemail js here");

  $(".memberdata.email textarea").each(function () {
    //console.log("textarea set height");
    this.setAttribute("style", "height:" + (this.scrollHeight) + "px;overflow-y:hidden;");
  }).on("input", function () {
    //console.log("textarea input");
    this.style.height = "auto";
    this.style.height = (this.scrollHeight) + "px";
  });
});

