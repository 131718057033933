import * as whintegration from '@mod-system/js/wh/integration';
import $ from 'jquery';

export function init() {
  document.documentElement.classList.add('pwa');
  addPWAFooter();
  addPWAHeader();

  $(document).on("click", "html.pwa #actionrefresh", function(_e) {
    _e.preventDefault();
    console.log("pwa: refresh");
    // keeping post data
    window.location.reload();
    // discarding post data
    //window.location.href = window.location.href;
  });

  var myprofilememberdatablocks = $("html.pwa.myprofile .memberdata.block");
  if (myprofilememberdatablocks.length>0) {
    $(myprofilememberdatablocks[0]).addClass("open");
  }
}

export function setMyClubURL(_url) {
  $(".nav-pwa .navitem.myclub a").attr("href", _url);
}

function addPWAFooter() {
    let navpwa = '';
    navpwa = navpwa + '<nav class="nav-pwa">';
    navpwa = navpwa + '  <ul>';
    //navpwa = navpwa + '    <li class="navitem"><a href="/leden/app/mystream/" title="Naar het beginscherm / activity-stream"><i class="fas fa-home"></i><h2>Home</h2></a></li>';
    navpwa = navpwa + '    <li class="navitem"><a href="/leden/" title="Naar het beginscherm / activity-stream"><i class="fas fa-home"></i><h2>Home</h2></a></li>';
    //navpwa = navpwa + '    <li class="navitem"><a href="/leden/app/search.html" title="Naar zoeken" class="navitem"><i class="fas fa-search"></i><h2>Zoeken</h2></a></li>';
    navpwa = navpwa + '    <li class="navitem"><a href="/leden/app/zoeken/?action=init" title="Naar zoeken" class="navitem"><i class="fas fa-search"></i><h2>Zoeken</h2></a></li>';
    navpwa = navpwa + '    <li class="navitem"><a href="/leden/app/activities/" title="Naar activiteiten" class="navitem"><i class="fas fa-calendar-alt"></i><h2>Activiteiten</h2></a></li>';
    //navpwa = navpwa + '    <li class="navitem"><a href="/leden/profiel.shtml" title="Naar mijn profiel" class="navitem"><i class="fas fa-id-card"></i><h2>Profiel</h2></a></li>';
    navpwa = navpwa + '    <li class="navitem"><a href="/leden/app/myprofile"/ title="Naar mijn profiel" class="navitem"><i class="fas fa-id-card"></i><h2>Profiel</h2></a></li>';
    //navpwa = navpwa + '    <li class="navitem myclub"><a href="https://www.rotarynew.wevolve.nl/amsterdamnieuwendam/Voorbeeld_map/" title="Naar overige items" class="navitem"><i class="fas fa-list"></i><h2>Meer</h2></a></li>';
    navpwa = navpwa + '    <li class="navitem myclub"><a href="/leden/" title="Naar mijn club" class="navitem"><i class="fas fa-list"></i><h2>Mijn club</h2></a></li>';
    navpwa = navpwa + '  </ul>';
    navpwa = navpwa + '</nav>';
    $('body').append(navpwa);
}

function addPWAHeader() {
    let navpwaheader = '';
    navpwaheader = navpwaheader + '<div class="nav-pwa-tmp">';
    //navpwaheader = navpwaheader + '<nav class="nav-pwa-header-home">';
    //navpwaheader = navpwaheader + '<img src="/resources/img/rotary-logo.svg" />';
    //navpwaheader = navpwaheader + '</nav>';
    navpwaheader = navpwaheader + '<nav class="nav-pwa-header">';
    //navpwaheader = navpwaheader + '<span class="left"><a href="javascript:history.back();" title="Terug"><i class="fas fa-angle-left"></i></a></span>';
    navpwaheader = navpwaheader + '<span class="left actions flex-items">';
    if (window.location.pathname !== '/leden/') {
      navpwaheader = navpwaheader + '<a class="action" href="javascript:history.back();" title="Terug"><i class="fas fa-less-than"></i></a>';
    }
    navpwaheader = navpwaheader + '<a class="action" id="actionrefresh" href="" title="Opnieuw laden"><i class="fas fa-redo"></i></a>';
    navpwaheader = navpwaheader + '</span>';

    navpwaheader = navpwaheader + '<span class="center">';
    navpwaheader = navpwaheader + '<div class="pwa-header__logocontainer logo-and-name"><a class="pwa-header__logolink" href="/"><div class="pwa-header__logoimg">';
    navpwaheader = navpwaheader + '<img class="rotary" src="' + whintegration.config.imgroot + 'pr-logo-color.png" />';
/*
    navpwaheader = navpwaheader + '<img class="rotary" src="/resources/img/rotary-logo.svg" />';
    navpwaheader = navpwaheader + '<img class="rotaract" src="/resources/img/rotaract-logo-simple.svg" />';
*/
    navpwaheader = navpwaheader + '</div><p>in Nederland</p></a><div class="menu"></div></div>';
    navpwaheader = navpwaheader + '</span>';

    //navpwaheader = navpwaheader + '<span class="title"><img class="ri-wheel" src="/resources/img/rotary-logo-wheel.svg" alt=""/><h2>Title here</h2></span>';
    navpwaheader = navpwaheader + '<span class="right">';
    //navpwaheader = navpwaheader + '<a href="" title=""><i class="fas fa-times"></i></a>';
    //navpwaheader = navpwaheader + '<a href="" title=""><i class="fas fa-plus"></i></a>';
    //navpwaheader = navpwaheader + '<a href="" title=""><i class="fas fa-edit"></i></a>';
    if (window.location.pathname === '/leden/') {
      //navpwaheader = navpwaheader + '<a href="" title=""><i class="fas fa-filter"></i></a>';
    }
    navpwaheader = navpwaheader + '</span>';
    navpwaheader = navpwaheader + '</nav>';
    //navpwaheader = navpwaheader + '<nav class="nav-pwa-header">';
    //navpwaheader = navpwaheader + '<span class="left"><a href="" title="Terug"><i class="fas fa-arrow-left"></i></a></span>';
    //navpwaheader = navpwaheader + '<h2>Title here</h2>';
    //navpwaheader = navpwaheader + '<span class="right text"><a href="" title="">opslaan</a>&nbsp;<a href="" title="">bewerken</a></span>';
    //navpwaheader = navpwaheader + '</nav>';
    navpwaheader = navpwaheader + '</div>';
    $('body').prepend(navpwaheader);
}
