import * as dompack from 'dompack';
import * as whintegration from '@mod-system/js/wh/integration';
import '@mod-system/js/wh/integration';
import 'dompack/browserfix/reset.css';
import '@mod-publisher/js/richcontent/all';
import * as wrdauth from '@mod-wrd/js/auth';

import "@fontsource/open-sans"; // Defaults to weight 400.
import "@fontsource/open-sans/300.css";
import "@fontsource/open-sans/700.css";

import $ from 'jquery';
import * as rpc from './data.rpc.json';

import 'swiper/swiper-bundle.min.css';

import './vars.scss';
import './base.scss';
import './main.scss';
import './pwa.scss';
import './components/header/';
import './components/footer/';
import './components/sidebar/';
import './components/video.scss';

import './sliderimages.scss';

/*
import lightGallery from 'lightgallery';
// Plugins
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'
*/

import * as util from './utilities';
import * as pwa from './pwa';

import './magazine';
import './magazine.scss';

import VideoPlayersController from '@mod-rotarynl/webdesigns/org2020/shared/js/videos';

/*
import './rtd/rtd';
*/

/*
  Pages
*/
import './pages/search/';
import './pages/login/';

import './pages/app/search/search';
import './pages/app/organization/organization';
import './pages/app/person/person';
import './pages/app/membershipcard/membershipcard';
import './pages/app/activities/activities';
import './pages/app/activityattendances/activityattendances';
import './pages/app/birthdays/birthdays';
import './pages/app/email/email';
import './pages/app/mystream/mystream';
import './pages/app/myprofile/myprofile';

import './pages/allclubslist/allclubslist';
import './pages/clubsearch/clubsearch';
import './pages/photoalbum/photoalbum';
import './pages/event/event';


/*
  widgets
*/
import '@mod-rotarynl/webdesigns/org2020/widgets/borderedimageblocks/borderedimageblocks';
import '@mod-rotarynl/webdesigns/org2020/widgets/button/button';
import '@mod-rotarynl/webdesigns/org2020/widgets/floatingblock/floatingblock';
import '@mod-rotarynl/webdesigns/org2020/widgets/fullvideo/fullvideo';
import '@mod-rotarynl/webdesigns/org2020/widgets/photoalbum/photoalbum';
import '@mod-rotarynl/webdesigns/org2020/widgets/photoslider/photoslider';
//import '@mod-rotarynl/webdesigns/org2020/widgets/rotaryprojects/rotaryprojects';
import '@mod-rotarynl/webdesigns/org2020/widgets/socialwall/socialwall';
import '@mod-rotarynl/webdesigns/org2020/widgets/textimage/textimage';
import '@mod-rotarynl/webdesigns/org2020/widgets/twocolumnstext/twocolumnstext';

import '@mod-rotarynl/webdesigns/org2020/widgets/old/contentbar/contentbar';
import '@mod-rotarynl/webdesigns/org2020/widgets/old/sliderimages/sliderimages';
import '@mod-rotarynl/webdesigns/org2020/widgets/old/threecolumns/threecolumns';

/*
  magazine widgets
*/
import '@mod-rotarynl/widgets/largeimageandtext/largeimageandtext';
import '@mod-rotarynl/widgets/counter/counter';
import '@mod-rotarynl/widgets/foldableitems/foldableitems';
import '@mod-rotarynl/widgets/linkeditemsonthispage/linkeditemsonthispage';
import '@mod-rotarynl/widgets/motionblock/motionblock';
import '@mod-rotarynl/widgets/twocolumns/twocolumns';
import '@mod-rotarynl/widgets/imagewithoverlays/imagewithoverlays';
import '@mod-rotarynl/widgets/baseblock/baseblock';
import '@mod-rotarynl/widgets/stickybackgroundimageblock/stickybackgroundimageblock';
import '@mod-rotarynl/widgets/customalignedblock/customalignedblock';


//console.log(whintegration);

var deferredPWAPrompt;

window.___isInStandaloneMode = (window.navigator.standalone) || (window.matchMedia('(display-mode: standalone)').matches) || document.referrer.includes('android-app://');

if (whintegration.config.dtapstage === "ddevelopment") {
  ___isInStandaloneMode = true;
}

if (window.___isInStandaloneMode) {
  //if ((window.location.pathname === '/') || (window.location.pathname === '/leden/')) {  /* fixme: jannes: use cookie, so we can only redirect on login? */
  //  console.log('redirect');
  //  window.location.href = '/leden/app/mystream';
  //}
  pwa.init();
}
//document.documentElement.classList.add('init');
//window.setTimeout(() => document.documentElement.classList.add('init'), 50);

if (util.isiOS()) {
  $("html").addClass("iOS");
}

if ('serviceWorker' in navigator) {
  window.addEventListener('load', function() {
    navigator.serviceWorker.register('/pwa-sw.js').then(function(_registration) {
      // Registration was successful
      //console.log('ServiceWorker registration successful with scope: ', _registration.scope);
    }, function(err) {
      // registration failed :(
      console.log('ServiceWorker registration failed: ', err);
    });
  });
}
else {
  console.log("no serviceworker in navigator");
}


dompack.onDomReady(() => {

  // set a timeout to make sure we show the page even with errors
  //window.setTimeout(() => document.documentElement.classList.add('init'), 100);
  document.documentElement.classList.add('init');

  //window.addEventListener('scroll', ev => onScroll(), util.supportsPassive() ? { passive: true } : false);
  window.addEventListener('scroll', ev => onScroll());
  onScroll();

  setupAnchorNavigation();

/*
  if (window.___isInStandaloneMode) {
    pwa.init();
  }
  else if (whintegration.config.dtapstage === "development") {
    ___isInStandaloneMode = true;
    pwa.init();
  }
*/

  let loginstatus = _getLoginStatus();

/*
$(document).on("click", "body", function(_e) {
  _e.preventDefault();
  console.log("body click");
});
*/

$(document).on("click", "header", function(_e) {
  //_e.preventDefault();
  _e.stopPropagation();
  console.log("header click");
});

$(document).on("click", ".js-toggle-mobile-search", function(_e) {
  _e.preventDefault();
  let prevslidingmenustateopen = $("html").hasClass("sliding-menu__state--open");
  $('html').removeClass('sliding-menu__state--open');
  if (prevslidingmenustateopen) {
    resetBody();
  }
  $('html').toggleClass('mobile-search-open');
  //mobilemenu.closeMenu();
});

// add 'scroll to top' action
$(document).on("click", ".js-scroll-to-top", function(_e) {
  _e.preventDefault();
  $('html, body').animate({ scrollTop: 0 }, 600);
});

$(document).on("click", "header .sliding-menu__col1 li.hassubitems > a", function(_e) {
  _e.preventDefault();
  _e.stopPropagation();
  console.log("toggle subitems");
  $(this).parent().toggleClass("state--open");
});

$(document).on("click", "header .hamburger.js-toggle-sliding-menu", function(_e) {
  _e.preventDefault();
  _e.stopPropagation();
  let prevslidingmenustateopen = $("html").hasClass("sliding-menu__state--open");
  console.log("prev:", prevslidingmenustateopen);
  if (prevslidingmenustateopen) {
    resetBody();
  }
  else {
    setBody();
  }
  $('html').removeClass('mobile-search-open');
  $("html").toggleClass("sliding-menu__state--open");
    
});

  let observer_options = {
    treshold: [0],
    rootMargin: '0px',
  }
  var observer = new IntersectionObserver(handleInViewItems, observer_options);
  let elements = document.querySelectorAll(".w-observe");
  for(let element of elements)
  {
    observer.observe(element);
  }

  var videoplayerstocontrol = $(".w-fullvideo .c-video");
  if (videoplayerstocontrol && (videoplayerstocontrol.length>0) ) {
    var videoplayerscontroller = new VideoPlayersController(videoplayerstocontrol);
  }

  $(document).on("click", ".filter.dropdown .filter-title", function(_e) {
    $(this).parent().find("ul").slideToggle();
  });

  // fixme: jannes: extra? load logolinks
  $(document).on("click", ".topbar__loginmenu .user.foldable, .topbar .ttopbar__logolink.haslinks", function(_e) {
    //console.log("show menu");
    _e.preventDefault();
    $(this).parent().toggleClass("open");
    $(this).parent().find(".menu").slideToggle();
  });

  if ($("#inputpersonfilter").length>0) {
    $(document).on("keyup", "#inputpersonfilter", function(_e) {
      var filtertext = $("#inputpersonfilter").val().toLowerCase();
      if (filtertext=="") {
        $("#smoelenboek .smoelenboekmember").removeClass("nodisplay");
      }
      else {
        var filteritems = $("#smoelenboek .smoelenboekmember").not("[data-person-name*='" + filtertext + "']");
        $("#smoelenboek .smoelenboekmember.nodisplay").not(filteritems).removeClass("nodisplay");
        filteritems.addClass("nodisplay");
      }
    });
    if ($("#inputpersonfilter").val().length > 0) {
      $("#inputpersonfilter").keyup();
    }
  }

  $(window).on("beforeinstallprompt", function(_e) {
    console.log("on 'beforeinstallprompt' event.");
    _e.preventDefault();
    deferredPWAPrompt = _e.originalEvent;
    var htmlelement = document.documentElement;
    htmlelement.classList.add('install-pwa');
  });

  $(document).on("click", "html.install-pwa .button-install-pwa", async (_e) => {
    console.log("click install-pwa");
    _e.preventDefault();
    _e.stopPropagation();
    $("html").removeClass("install-pwa");
    if (deferredPWAPrompt !== undefined) {
      console.log("deferredPWA prompt is not null");
      deferredPWAPrompt.prompt();
      const { outcome } = await deferredPWAPrompt.userChoice;
      console.log(`User response to the install prompt: ${outcome}`);
      deferredPWAPrompt = null;
    }
    else {
      console.log("deferredPWA prompt is null");
    }
  });

}); // dompack.onDomReady

function setBody()
{
  console.log("setBody");
  let scrolltop = -1 * $(document).scrollTop();
  console.log('scrolltop:', scrolltop);
  //$("body").addClass("position--fixed");
  $("body").css('top', `${scrolltop}px`);
}

function resetBody()
{
  console.log("resetBody");
  const top = parseInt($('body').css('top') || 0);
  $("body").removeClass("position--fixed");
  $("body").css('top', 0);
  const scrolltop = -1 * top;
  setTimeout(function(e) {window.scrollTo(0, scrolltop);}
  , 10);
  console.log("reset top:", scrolltop);
}

function getScrollPositionForElement($el) {
  if (!$el || $el.length === 0) {
    console.error('no element');
    return 0;
  }

  const $topbar = $('.topbar');
  let scrollOffset = 0;
  if ($topbar.css('position') === 'fixed') {
    scrollOffset = $topbar.get(0).getBoundingClientRect().height;
  }

  return $el.get(0).getBoundingClientRect().top - scrollOffset;
}

function onScroll(_e) {
  let scrollY = getScrollY();

  // show the 'scroll to top' button after scrolling a bit
  dompack.toggleClass(document.documentElement, 'show-scroll-to-top', scrollY > 50);

  // fade out the hero image title
  dompack.toggleClass(document.documentElement, 'hide-heroimage-title', scrollY > 130);
}

function getScrollY() {
  return window.scrollY || window.pageYOffset || document.body.scrollTop;
}

function handleInViewItems(_items, _observer)
{
  _items.forEach((_item) => {
    if (_item.isIntersecting) {
      console.log("item getting into view", _item);
      _item.target.classList.add("w-inview");
    }
    else {
      //console.log("item getting out of view", _item);
      _item.target.classList.remove("w-inview");
    }
    let targetitem = {
      boundingClientRect: _item.boundingClientRect,
      intersectionRatio: _item.intersectionRatio,
      intersectionRect: _item.intersectionRect,
      isIntersecting: _item.isIntersecting,
      rootNounds: _item.rootBounds,
      target: _item.target,
      time: _item.time,
    }
    //console.log(targetitem);
  });
}




function setupAnchorNavigation(reset = false) {
  handleTitleH1(); // fixme: jannes: if splith1nav then setupAnchorNavigation is called twice
  if (!document.documentElement.classList.contains('enable-h2-navigation')) {
    return;
  }

  const $anchorNav = $('#contentwrapper-anchornav');
  if (reset === true) {
    $anchorNav.empty();
  }

  const $h2s = $('main > h2:visible,main h2.can-nav:visible');
  if ($h2s.length == 0) {
    $anchorNav.hide();
    return;
  }

  $anchorNav.show();

  $h2s.each(function(idx) {
    const $link = $(`<a href="#">${$(this).text()}</a>`);
    $link.click((evt) => {
      evt.preventDefault();

/* fixme; jannes: scrolltotop : history.go(-1) if state pushed? */
/*
const scrolltop = $(document).scrollTop();
var stateObj = { scrollTop: scrolltop };
history.pushState(stateObj, "scroll in page");
console.log("pushState", stateObj);
*/
      $('html, body').animate({ scrollTop: getScrollPositionForElement($(this)) }, 600);
    });

    $anchorNav.append($link);
    if (idx < $h2s.length - 1) {
      $anchorNav.append('<span class="contentwrapper__anchornav-separator">|</span>');
    }
  });
}

function handleTitleH1()
{
  var $pageh1 = $('main.contentwrapper__col--content > h1');
  if ($pageh1.length == 1) {
    if ($("html").hasClass("splith1"))
    {
      var $visibleh1 = $(".contentwrapper__contents h1.wh-heading1:not([hidden])");
      if ($visibleh1.length>0) {
        $pageh1.addClass("nodisplay");
      }
      else {
        $pageh1.removeClass("nodisplay");
      }
    }
    else {
      var contentselements = $(".contentwrapper__contents").children();
      if (contentselements.length>0) {
        if ((contentselements[0].tagName == "H1") && (contentselements[0].classList.contains("wh-heading1")))
        {
          console.log("starts with h1");
          $pageh1.addClass("nodisplay");
        }
      }
    }
  }
}


/*
  login
*/

async function _getLoginStatus() {
  let loginstatus = await rpc.getLoginStatus() || {};
  //console.log("loginstatus: ", loginstatus);

  let gtag_user_properties = {};
  if (window.___isInStandaloneMode) {
    gtag_user_properties.browser_mode = "pwa";
  }
  else {
    gtag_user_properties.browser_mode = "normal";
  }

  if (loginstatus && loginstatus.ok && loginstatus.item)
  {
    // hide login links
    $(".topbar__loginlink").addClass("display--none");
    $(".menu__col2__item.loginlink").addClass("display--none");

    //console.log(loginstatus.item.loginstate.displayname);
    $(".topbar__loginmenu .user .name a").text(loginstatus.item.displayname);
    $(".topbar__loginmenu .menu ul").html(loginstatus.item.mylinkshtml);
    $(".topbar__loginmenu").removeClass("display--none");

    if (loginstatus.item.logolinkshtml)
    {
      $(".topbar__logocontainer .menu").html('<ul>' + loginstatus.item.logolinkshtml + '</ul>');
      $(".topbar .topbar__logolink").addClass("haslinks");
    }

    $(".mobilemenu__navlistitem--user").addClass("hassubitems");
    $(".mobilemenu__navlistitem--user > a span.title").text(loginstatus.item.displayname);
    $(".mobilemenu__navlistitem--user ul").html(loginstatus.item.mylinkshtml);
    $(".mobilemenu__navlistitem--user").removeClass("nodisplay");

    if (loginstatus.item.islogin_rotary)
    {
      //console.log("login rotary");
      $("html").addClass("login-rotary");
    }
    if (loginstatus.item.islogin_rotaract)
    {
      //console.log("login rotaract");
      $("html").addClass("login-rotaract");
    }

    if ($(".districtcommittees tr.person").length>0) {
      $(document).on("click", ".districtcommittees tr.person", function(_e) {
        const link = "/leden/app/person/?ed=" + $(this).data("ed");
        window.location.href = link;
      });
    }

    if ($("#clubmembers .memberinfo").length>0) {
      $(document).on("click", "#clubmembers .memberinfo", function(_e) {
        const link = "/leden/app/person/?ed=" + $(this).data("ed");
        window.location.href = link;
      });
    }
    if (loginstatus.item.isexternallogin)
    {
      $("html").addClass("external--isloggedin");
    }
    else {
      var htmlcheckboxes = "";
      htmlcheckboxes += '<input type="checkbox" id="chkpersons" name="chkpersons" checked="checked" class="hidden" />';
      htmlcheckboxes += '<input type="checkbox" id="chkclubs" name="chkclubs" checked="checked" class="hidden" />';
      htmlcheckboxes += '<input type="checkbox" id="chkhelpdesk" name="chkhelpdesk" checked="checked" class="hidden" />';
      htmlcheckboxes += '<input type="checkbox" id="chkwebsite" name="chkwebsite" checked="checked" class="hidden" />';
      //$("form.topbar__searchform").attr("action", "/leden/app/zoeken/");
      $("form.topbar__searchform").attr("action", "/leden/app/search.html");
      $("form.topbar__searchform").append(htmlcheckboxes);
    }

    if (loginstatus.item.clubname)
    {
      $(".pwa-header__logocontainer.logo-and-name p").text(loginstatus.item.clubname);
    }
    //console.log("mycluburl", loginstatus.item.pwamycluburl);
    if (loginstatus.item.pwamycluburl)
    {
      $(".nav-pwa .navitem.myclub a").attr("href", loginstatus.item.pwamycluburl);
    }

    if (window.top !== window.self) {
      $("html").addClass("framed");
      //console.log("framed");
    }

    let contextdata = _getContextData();

    let gtagclubname = loginstatus.item.clubname;
    if (loginstatus.item.islogin_rotary) {
      gtagclubname += " (Rotary)";
    }
    else if (loginstatus.item.islogin_rotaract) {
      gtagclubname += " (Rotaract)";
    }
    //console.log(gtagclubname);
    if (typeof gtagclubname !== "undefined") {
      gtag_user_properties.club_name = gtagclubname;
    }
  }
  //console.log("gtag_up", gtag_user_properties);
  if (typeof gtag !== "undefined") {
    gtag('set', 'user_properties', gtag_user_properties);
  }
};

async function _getContextData() {
  var publisherdata = $("button.open-in-publisher").data("publisher");
  if (publisherdata)
  {
    let contextdata = await rpc.getContextData({ 'fsobjectid': publisherdata.id }) || {};
    //console.log("contextdata: ", contextdata);
    if (contextdata && contextdata.ok && contextdata.item)
    {
      if (contextdata.item.hasfullaccessonfsobject) {
        //console.log("hasfullaccessonfsobject");
        if ($("html").hasClass("pwa")) {
          $("button.open-in-publisher").removeClass("show");
        } else {
          $("button.open-in-publisher").addClass("show");
        }
        $(document).on("click", "button.open-in-publisher", function(_e) {
          //console.log("open-in-publisher");
          //var publisherdata = $(this).data("publisher");
          //var publisherdata = $.parseJSON($(this).data("publisher"));
          //console.log(publisherdata);
          var url = "/access/portal/?app=publisher(" + publisherdata.id + ")";
          //console.log(url);
          window.open(url, "_blank");
        });
      }
      if (contextdata.item.hasbrowseonany) {
        $(".widgetblock.publisher-any").css({'display':'flex'});
      }
    }
  }
}
