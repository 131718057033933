import * as dompack from 'dompack';
import $ from 'jquery';

import "./membershipcard.scss";

dompack.onDomReady(() => {

/*
  $(document).on("click", ".memberdata.membership .person__photo-toggle", function(_e) {
    //console.log("click photo");
    _e.preventDefault();
    $(this).toggleClass("img__size--big");
  });
*/

});

