import * as dompack from 'dompack';
import $ from 'jquery';

import "./birthdays.scss";

dompack.onDomReady(() => {


});

